import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  HashRouter,
} from "react-router-dom";
import "./App.css";
import About from "./Pages/About/About";
import Blog from "./Pages/Blog/Blog";
import Contact from "./Pages/Contact/Contact";
import Home from "./Pages/Home/Home";
import Portfolio from "./Pages/Portfolio/Portfolio";
import GlassGhor from "./Pages/ProjectDetails/GlassGhor/GlassGhor";
import HealthCare from "./Pages/ProjectDetails/HealthCare/HealthCare";
import TravelAgent from "./Pages/ProjectDetails/TravelAgent/TravelAgent";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/portfolio">
            <Portfolio />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/travelAgent">
            <TravelAgent />
          </Route>
          <Route path="/glassGhor">
            <GlassGhor />
          </Route>
          <Route path="/healthCare">
            <HealthCare />
          </Route>
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
