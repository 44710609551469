import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home.css";
import homeDP from "../../img/bestDP.png";
import blob from "../../img/blob.png";
import Typewriter from "typewriter-effect";
import { AiOutlineFileDone } from "react-icons/ai";
import {
  BsFacebook,
  BsGithub,
  BsLinkedin,
  BsBehance,
  BsFillHouseFill,
  BsPersonFill,
  BsGrid1X2Fill,
  BsChatDotsFill,
  BsTextIndentLeft,
} from "react-icons/bs";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="home-body">
        <section className="container">
          <div className="row row-container align-items-center mb-5">
            <div className="col-md-6 col-12 left-side">
              <img
                src={homeDP}
                className="img-fluid desk-dp"
                width="500px"
                rounded
                alt=""
                data-aos="fade-up"
              />
              <img
                src={blob}
                className="img-fluid phone-dp"
                width="500px"
                rounded
                alt=""
                data-aos="fade-up"
              />
            </div>
            <div className="col-md-6 col-12 right-side">
              <h1 data-aos="fade-down">
                I'M <span className="text-warning">JOYNAL</span>.
              </h1>
              <h3 className="text-warning">
                <Typewriter
                  options={{
                    strings: [
                      "MERN-Stack <span style='color: white;'>Developer</span>.",
                      "Front-end <span style='color: white;'>Developer</span>.",
                      "Back-end <span style='color: white;'>Developer</span>.",
                      "FullStack <span style='color: white;'>Developer</span>.",
                      "JavaScript <span style='color: white;'>Developer</span>.",
                      "React <span style='color: white;'>Developer</span>.",
                      "<span style='color: white;'>Web</span> Designer.",
                      "<span style='color: white;'>Graphics</span> Designer.",
                      "<span style='color: white;'>Brand</span> Designer.",
                      "<span style='color: white;'>Logo</span> Designer.",
                    ],
                    delay: 30,
                    deleteSpeed: 30,
                    pauseFor: 2000,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h3>
              <p data-aos="fade-left">
                I'm a MERN-Stack Developer. I started this programming journey
                in 2019 but at that time it was very difficult and annoying for
                me. Then I quit programming, started learning Graphic Design,
                and then I was considered as a fairly good level Designer to my
                clients. But in 2021, I set my life goal. I wanted to make
                myself a programmer. Now I am a junior MERN-Stack Developer.
              </p>

              <div className="social-sec" data-aos="fade-up">
                <a
                  href="https://github.com/ibntofajjal"
                  target="_blank"
                  rel="noreferrer"
                  className="social-icon"
                >
                  <BsGithub />
                </a>
                <a
                  href="https://facebook.com/ibntofajjal.id"
                  target="_blank"
                  rel="noreferrer"
                  className="social-icon"
                >
                  <BsFacebook />
                </a>
                <a
                  href="https://linkedin.com/in/ibntofajjal"
                  target="_blank"
                  rel="noreferrer"
                  className="social-icon"
                >
                  <BsLinkedin />
                </a>
                <a
                  href="https://behance.net/ibntofajjal"
                  target="_blank"
                  rel="noreferrer"
                  className="social-icon"
                >
                  <BsBehance />
                </a>
              </div>

              <br />
              <a
                href="https://drive.google.com/file/d/1GH8ViknsP3MgiWUjQ163ffC91osV_beG/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
                class="btn btn-sm animated-button thar-three mb-5"
              >
                <AiOutlineFileDone /> Resume
              </a>
            </div>
          </div>
          {/* ====================== NAVIGATION START=============================*/}
          <div className="navbar desk-nav">
            <div className="nav-links">
              <div className="nav-url">
                <Link to="./home">
                  <p className="text-white nav-icon">
                    <BsFillHouseFill />
                  </p>
                </Link>
              </div>
              <div className="nav-url">
                <Link to="./about">
                  <p className="text-white nav-icon">
                    <BsPersonFill />
                  </p>
                </Link>
              </div>
              <div className="nav-url">
                <Link to="./portfolio">
                  <p className="text-white nav-icon">
                    <BsGrid1X2Fill />
                  </p>
                </Link>
              </div>
              <div className="nav-url">
                <Link to="./contact">
                  <p className="text-white nav-icon">
                    <BsChatDotsFill />
                  </p>
                </Link>
              </div>
              <div className="nav-url">
                <Link to="./blog">
                  <p className="text-white nav-icon">
                    <BsTextIndentLeft />
                  </p>
                </Link>
              </div>
            </div>
          </div>
          {/* FOR MOBILE */}
          <div className="phone-nav">
            <div className="nav-links">
              <Link to="./home">
                <BsFillHouseFill
                  id="target1"
                  className="text-white nav-icon fss-9"
                />
              </Link>

              <Link to="./about">
                <BsPersonFill
                  id="target2"
                  className="text-white nav-icon fss-9"
                />
              </Link>

              <Link to="./portfolio">
                <BsGrid1X2Fill
                  id="target3"
                  className="text-white nav-icon fss-9"
                />
              </Link>

              <Link to="./contact">
                <BsChatDotsFill
                  id="target4"
                  className="text-white nav-icon fss-9"
                />
              </Link>
              <Link to="./blog">
                <BsTextIndentLeft
                  id="target4"
                  className="text-white nav-icon fss-9"
                />
              </Link>
            </div>
          </div>
          {/* ====================== NAVIGATION END=============================*/}
        </section>
      </div>
    </>
  );
};

export default Home;
