import React, { useEffect } from "react";
import "./TravelAgent.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  BsFillHouseFill,
  BsPersonFill,
  BsGrid1X2Fill,
  BsChatDotsFill,
  BsArrowUpRightCircleFill,
  BsGithub,
  BsFillBookmarkHeartFill,
  BsTextIndentLeft,
} from "react-icons/bs";
import travelDetail from "../../../img/travelDetail.png";
import { Link } from "react-router-dom";

const TravelAgent = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <section className="project-details-section container">
        <h1 className="text-center page-title mt-5" data-aos="fade-down">
          Travel <span className="text-warning">Agent</span>
        </h1>
        <img
          src={travelDetail}
          className="img-fluid mb-5"
          alt=""
          data-aos="zoom-in-up"
        />
        <div className="project-material">
          <div className="view-links" data-aos="zoom-in-down">
            <a
              href="https://github.com/ibntofajjal/travel-agent-website"
              rel="noreferrer"
              target="_blank"
            >
              <BsGithub /> GitHub
            </a>
            <a
              href="https://travel-mern-v2.netlify.app/"
              rel="noreferrer"
              target="_blank"
            >
              Live
              <BsArrowUpRightCircleFill />
            </a>
          </div>
          <h5
            className="mt-3 bg-primary text-center text-white py-1"
            data-aos="zoom-in-down"
          >
            Technology
          </h5>
          <div className="technology" data-aos="zoom-in-down">
            <p>React</p>
            <p>Node</p>
            <p>Express</p>
            <p>MongoDB</p>
            <p>Firebase</p>
            <p>Heroku</p>
          </div>
        </div>
        <div className="detail">
          <h4>
            ● People can Book The Travel/Tour Service
            <br />
            <br /> ● Users can register/login to this site. <br />
            <br />● This website has one private route, if users want to access
            that route then simply they can log in and access the route. And
            users can add new services on the site.
          </h4>
        </div>
      </section>

      {/* ====================== NAVIGATION START=============================*/}
      <div className="navbar desk-nav">
        <div className="nav-links">
          <div className="nav-url">
            <Link to="./home">
              <p className="text-white nav-icon">
                <BsFillHouseFill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./about">
              <p className="text-white nav-icon">
                <BsPersonFill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./portfolio">
              <p className="text-white nav-icon">
                <BsGrid1X2Fill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./contact">
              <p className="text-white nav-icon">
                <BsChatDotsFill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./blog">
              <p className="text-white nav-icon">
                <BsTextIndentLeft />
              </p>
            </Link>
          </div>
        </div>
      </div>
      {/* FOR MOBILE */}
      <div className="phone-nav">
        <div className="nav-links">
          <Link to="./home">
            <BsFillHouseFill
              id="target1"
              className="text-white nav-icon fss-9"
            />
          </Link>

          <Link to="./about">
            <BsPersonFill id="target2" className="text-white nav-icon fss-9" />
          </Link>

          <Link to="./portfolio">
            <BsGrid1X2Fill id="target3" className="text-white nav-icon fss-9" />
          </Link>

          <Link to="./contact">
            <BsChatDotsFill
              id="target4"
              className="text-white nav-icon fss-9"
            />
          </Link>
          <Link to="./blog">
            <BsTextIndentLeft
              id="target4"
              className="text-white nav-icon fss-9"
            />
          </Link>
        </div>
      </div>
      {/* ====================== NAVIGATION END=============================*/}
    </div>
  );
};

export default TravelAgent;
