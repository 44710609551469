import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  BsFillHouseFill,
  BsPersonFill,
  BsGrid1X2Fill,
  BsChatDotsFill,
  BsCheckCircleFill,
  BsTextIndentLeft,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import blob from "../../img/blob.png";
import "./About.css";
import html from "../../img/icons/html.png";
import css from "../../img/icons/css.png";
import bs from "../../img/icons/bs.png";
import rbs from "../../img/icons/reactBS.png";
import react from "../../img/icons/react.png";
import js from "../../img/icons/js.png";
import tailwind from "../../img/icons/tailwind.png";
import mongo from "../../img/icons/mongo.png";
import node from "../../img/icons/node.png";
import json from "../../img/icons/json.png";
import api from "../../img/icons/api.png";
import express from "../../img/icons/express.png";

import netlify from "../../img/icons/netlify.png";
import heroku from "../../img/icons/heroku.png";
import git from "../../img/icons/git.png";
import devtool from "../../img/icons/devtool.png";
import firebase from "../../img/icons/firebase.png";
import vscode from "../../img/icons/vscode.png";

const About = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <section className="container">
        <div className="about-section">
          <h1 className="text-center page-title" data-aos="fade-down">
            ABOUT <span className="text-warning">ME</span>
          </h1>
          {/* About */}
          <div className="row">
            <div className="col-md-6 col-12 info" data-aos="fade-right">
              <img
                src={blob}
                className="img-fluid phone-dp"
                width="500px"
                rounded
                alt=""
              />
              <h1>
                PERSONAL <span className="text-warning">INFO</span>
              </h1>
              <div className="row mt-3">
                <div className="col-6 info-left">
                  <h6>
                    <span className="text-white-50">
                      First Name: <br />
                    </span>
                    <b>Joynal</b>
                  </h6>
                  <br />
                  <h6>
                    <span className="text-white-50">
                      Nationality: <br />{" "}
                    </span>
                    <b>Bengali</b>
                  </h6>
                  <br />
                  <h6>
                    <span className="text-white-50">
                      Address: <br />
                    </span>
                    <b>Sylhet, Bangladesh</b>
                  </h6>
                  <br />
                  <h6>
                    <span className="text-white-50">
                      Skype: <br />
                    </span>
                    <b>
                      <a
                        href="https://join.skype.com/invite/wPFCcxna3w22"
                        className="text-white text-decoration-none"
                      >
                        Joynal Bin Tofajjal 
                        <BsCheckCircleFill className="text-warning" />
                      </a>
                    </b>
                  </h6>
                  <br />
                </div>
                <div className="col-6 info-right">
                  <h6>
                    <span className="text-white-50">
                      Last Name: <br />{" "}
                    </span>
                    <b className="sm">Bin Tofajjal</b>
                  </h6>
                  <br />
                  <h6>
                    <span className="text-white-50">
                      Freelance: <br />
                    </span>
                    <b>
                      Available 
                      <BsCheckCircleFill className="text-warning" />
                    </b>
                  </h6>
                  <br />
                  <h6>
                    <span className="text-white-50">
                      Phone: <br />{" "}
                    </span>
                    <b>+8801636024193</b>
                  </h6>
                  <br />
                  <h6>
                    <span className="text-white-50">
                      Email: <br />
                    </span>
                    <b>joynal@bintofajjal.info</b>
                  </h6>
                </div>
                <br />
              </div>
            </div>

            <div
              className="col-md-6 col-12 feedbacks container"
              data-aos="fade-left"
            >
              <div className="row">
                <div className="col-lg-6 col-6 feedback">
                  <h1 className="text-warning">1+</h1>
                  <h6>Years</h6>
                  <h6>Experience</h6>
                </div>
                <div className="col-lg-6 col-6 feedback">
                  <h1 className="text-warning">7+</h1>
                  <h6>Projects</h6>
                  <h6>Complete</h6>
                </div>
                <div className="col-lg-6 col-6 feedback">
                  <h1 className="text-warning">5+</h1>
                  <h6>Happy</h6>
                  <h6>Clients</h6>
                </div>
                <div className="col-lg-6 col-6 feedback">
                  <h1 className="text-warning">1+</h1>
                  <h6>Award</h6>
                  <h6>Won</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SKILLS SECTION */}
      <div className="skills-section mb-5">
        <h1 className="text-center page-title" data-aos="fade-down">
          <span className="text-warning">MY</span> SKILLS
        </h1>
        <div className="languages">
          <div className="row container mx-auto languages-skills">
            <div className="skill" data-aos="fade-up">
              <img src={html} className="img-fluid" width="100px" alt="" />
              <h6>HTML</h6>
            </div>
            <div className="skill" data-aos="fade-down">
              <img src={css} className="img-fluid" width="100px" alt="" />
              <h6>CSS</h6>
            </div>
            <div className="skill" data-aos="fade-up">
              <img src={bs} className="img-fluid" width="100px" alt="" />
              <h6>BOOTSTRAP</h6>
            </div>
            <div className="skill" data-aos="fade-down">
              <img src={tailwind} className="img-fluid" width="100px" alt="" />
              <h6>TAILWIND</h6>
            </div>
            <div className="skill" data-aos="fade-up">
              <img src={js} className="img-fluid" width="100px" alt="" />
              <h6>JAVASCRIPT</h6>
            </div>
            <div className="skill" data-aos="fade-down">
              <img src={react} className="img-fluid" width="100px" alt="" />
              <h6>REACT</h6>
            </div>
            <div className="skill" data-aos="fade-up">
              <img src={rbs} className="img-fluid" width="100px" alt="" />
              <h6>REACT-BS</h6>
            </div>
            <div className="skill" data-aos="fade-down">
              <img src={node} className="img-fluid" width="100px" alt="" />
              <h6>NODE</h6>
            </div>
            <div className="skill" data-aos="fade-up">
              <img src={express} className="img-fluid" width="100px" alt="" />
              <h6>EXPRESS</h6>
            </div>
            <div className="skill" data-aos="fade-down">
              <img src={mongo} className="img-fluid" width="100px" alt="" />
              <h6>MONGODB</h6>
            </div>
            <div className="skill" data-aos="fade-up">
              <img src={json} className="img-fluid" width="100px" alt="" />
              <h6>JSON</h6>
            </div>
            <div className="skill" data-aos="fade-down">
              <img src={api} className="img-fluid" width="100px" alt="" />
              <h6>API</h6>
            </div>
          </div>
        </div>
      </div>

      {/* TOOLS */}
      <div className="tools mb-5">
        <h1 className="text-center page-title" data-aos="fade-down">
          USAGE <span className="text-warning">TOOLS</span>
        </h1>
        <div className="row container mx-auto languages-skills">
          <div className="skill" data-aos="fade-up">
            <img src={firebase} className="img-fluid" width="100px" alt="" />
            <h6>FIREBASE</h6>
          </div>
          <div className="skill" data-aos="fade-down">
            <img src={netlify} className="img-fluid" width="100px" alt="" />
            <h6>NETLIFY</h6>
          </div>
          <div className="skill" data-aos="fade-up">
            <img src={heroku} className="img-fluid" width="100px" alt="" />
            <h6>HEROKU</h6>
          </div>
          <div className="skill" data-aos="fade-down">
            <img src={devtool} className="img-fluid" width="100px" alt="" />
            <h6>DEVTOOL</h6>
          </div>
          <div className="skill" data-aos="fade-up">
            <img src={git} className="img-fluid" width="100px" alt="" />
            <h6>GIT</h6>
          </div>
          <div className="skill" data-aos="fade-down">
            <img src={vscode} className="img-fluid" width="100px" alt="" />
            <h6>VS CODE</h6>
          </div>
        </div>
      </div>

      {/* ====================== NAVIGATION START=============================*/}
      <div className="navbar desk-nav">
        <div className="nav-links">
          <div className="nav-url">
            <Link to="./home">
              <p className="text-white nav-icon">
                <BsFillHouseFill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./about">
              <p className="text-white nav-icon">
                <BsPersonFill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./portfolio">
              <p className="text-white nav-icon">
                <BsGrid1X2Fill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./contact">
              <p className="text-white nav-icon">
                <BsChatDotsFill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./blog">
              <p className="text-white nav-icon">
                <BsTextIndentLeft />
              </p>
            </Link>
          </div>
        </div>
      </div>
      {/* FOR MOBILE */}
      <div className="phone-nav" data-aos="fade-down">
        <div className="nav-links">
          <Link to="./home">
            <BsFillHouseFill
              id="target1"
              className="text-white nav-icon fss-9"
            />
          </Link>

          <Link to="./about">
            <BsPersonFill id="target2" className="text-white nav-icon fss-9" />
          </Link>

          <Link to="./portfolio">
            <BsGrid1X2Fill id="target3" className="text-white nav-icon fss-9" />
          </Link>

          <Link to="./contact">
            <BsChatDotsFill
              id="target4"
              className="text-white nav-icon fss-9"
            />
          </Link>
          <Link to="./blog">
            <BsTextIndentLeft
              id="target4"
              className="text-white nav-icon fss-9"
            />
          </Link>
        </div>
      </div>
      {/* ====================== NAVIGATION END=============================*/}
    </div>
  );
};

export default About;
