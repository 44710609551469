import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  BsFillHouseFill,
  BsPersonFill,
  BsGrid1X2Fill,
  BsChatDotsFill,
  BsArrowUpRightCircleFill,
  BsGithub,
  BsFillBookmarkHeartFill,
  BsTextIndentLeft,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import "./Portfolio.css";

import glass from "../../img/glass.png";
import travel from "../../img/travel.png";
import health from "../../img/health.png";

const Portfolio = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <section className="container">
        <div className="portfolio-section mb-5">
          <h1 className="text-center page-title mt-5">
            MY <span className="text-warning">PORTFOLIO</span>
          </h1>
        </div>
        <div className=" row my-projects">
          <div className="project " data-aos="flip-right" data-aos-offset="300">
            <h3 className="mt-2 mb-3 bg-warning rounded">
              <b>GlassGhor</b>
            </h3>
            <img src={glass} className=" img-fluid mb-3" alt="" />
            <p>
              GlassGhor is a responsive FullStack Website build with
              MongoDB,NodeJS,ExpressJS,ReactJs,Bootstrap,Material UI and
              Firebase Authentication
            </p>
            <div className="view-links">
              <a
                href="https://github.com/ibntofajjal/glass-ghor-website"
                rel="noreferrer"
                target="_blank"
              >
                <BsGithub /> GitHub
              </a>
              <a
                href="https://glasses-mern-v2.netlify.app/home"
                rel="noreferrer"
                target="_blank"
              >
                <BsArrowUpRightCircleFill /> Live
              </a>
              <br /> <br />
              <Link to="/glassGhor">
                <BsFillBookmarkHeartFill /> Details
              </Link>
            </div>
            <h5 className="mt-3 bg-primary text-white py-1">Technology</h5>
            <div className="technology">
              <p>React</p>
              <p>Node</p>
              <p>Express</p>
              <p>MongoDB</p>
              <p>Firebase</p>
              <p>Heroku</p>
            </div>
          </div>

          <div className=" project " data-aos="flip-left" data-aos-offset="300">
            <h3 className="mt-2 mb-3 bg-warning rounded">
              <b>Travel Agent</b>
            </h3>
            <img src={travel} className=" img-fluid mb-3" alt="" />

            <p>
              Travel-Agent is a responsive FullStack Website build with
              MongoDB,NodeJS,ExpressJS,ReactJs,Bootstrap and Firebase
              Authentication
            </p>
            <div className="view-links">
              <a
                href="https://github.com/ibntofajjal/travel-agent-website"
                rel="noreferrer"
                target="_blank"
              >
                <BsGithub /> GitHub
              </a>
              <a
                href="https://travel-mern-v2.netlify.app/"
                rel="noreferrer"
                target="_blank"
              >
                <BsArrowUpRightCircleFill /> Live
              </a>
              <br /> <br />
              <Link to="/travelAgent">
                <BsFillBookmarkHeartFill /> Details
              </Link>
            </div>
            <h5 className="mt-3 bg-primary text-white py-1">Technology</h5>
            <div className="technology">
              <p>React</p>
              <p>Node</p>
              <p>Express</p>
              <p>MongoDB</p>
              <p>Firebase</p>
              <p>Heroku</p>
            </div>
          </div>

          <div className="project " data-aos="flip-right" data-aos-offset="300">
            <h3 className="mt-2 mb-3 bg-warning rounded">
              <b>Health Care</b>
            </h3>
            <img src={health} className=" img-fluid mb-3" alt="" />

            <p>
              Health Care Consultation is a Single responsive Web page Built
              with React, Bootstrap, and Firebase Authentication.
            </p>
            <div className="view-links">
              <a
                href="https://github.com/ibntofajjal/health-care-website"
                rel="noreferrer"
                target="_blank"
              >
                <BsGithub /> GitHub
              </a>
              <a
                href="https://health-care-v2.netlify.app/"
                rel="noreferrer"
                target="_blank"
              >
                <BsArrowUpRightCircleFill /> Live
              </a>
              <br /> <br />
              <Link to="/healthCare">
                <BsFillBookmarkHeartFill /> Details
              </Link>
            </div>
            <h5 className="mt-3 bg-primary text-white py-1">Technology</h5>
            <div className="technology">
              <p>React</p>
              <p>CSS</p>
              <p>Bootstrap</p>
              <p>Firebase</p>
              <p>Netlify</p>
            </div>
          </div>
        </div>
      </section>
      {/* ====================== NAVIGATION START=============================*/}
      <div className="navbar desk-nav">
        <div className="nav-links">
          <div className="nav-url">
            <Link to="./home">
              <p className="text-white nav-icon">
                <BsFillHouseFill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./about">
              <p className="text-white nav-icon">
                <BsPersonFill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./portfolio">
              <p className="text-white nav-icon">
                <BsGrid1X2Fill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./contact">
              <p className="text-white nav-icon">
                <BsChatDotsFill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./blog">
              <p className="text-white nav-icon">
                <BsTextIndentLeft />
              </p>
            </Link>
          </div>
        </div>
      </div>
      {/* FOR MOBILE */}
      <div className="phone-nav">
        <div className="nav-links">
          <Link to="./home">
            <BsFillHouseFill
              id="target1"
              className="text-white nav-icon fss-9"
            />
          </Link>

          <Link to="./about">
            <BsPersonFill id="target2" className="text-white nav-icon fss-9" />
          </Link>

          <Link to="./portfolio">
            <BsGrid1X2Fill id="target3" className="text-white nav-icon fss-9" />
          </Link>

          <Link to="./contact">
            <BsChatDotsFill
              id="target4"
              className="text-white nav-icon fss-9"
            />
          </Link>
          <Link to="./blog">
            <BsTextIndentLeft
              id="target4"
              className="text-white nav-icon fss-9"
            />
          </Link>
        </div>
      </div>
      {/* ====================== NAVIGATION END=============================*/}
    </div>
  );
};

export default Portfolio;
