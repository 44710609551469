import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import emailjs from "emailjs-com";
import {
  BsFillHouseFill,
  BsPersonFill,
  BsGrid1X2Fill,
  BsChatDotsFill,
  BsMapFill,
  BsFillTelephoneFill,
  BsTelegram,
  BsFillEnvelopeOpenFill,
  BsTextIndentLeft,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import "./Contact.css";

const Contact = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "portfolio",
        "joynal-portfolio",
        e.target,
        "user_NiYFpdWSp3uS76cLcSjPL"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div className="contact-section container">
        <h1 className="text-center page-title mt-5" data-aos="fade-down">
          GET IN <span className="text-warning">TOUCH</span>
        </h1>
        <div className="row">
          <div className="col-md-4 col-12">
            <h3 data-aos="fade-right">
              <b>
                DON'T BE <span className="text-warning">SHY</span> !
              </b>
            </h3>
            <p className="my-3" data-aos="flip-down">
              Feel free to get in touch with me. I am always open to discussing
              new projects, creative ideas or opportunities to be part of your
              visions.
            </p>
            <div className="contact-info mt-5" data-aos="zoom-out-right">
              <div className="address d-flex">
                <div className="contact-icon me-4">
                  <h1>
                    <BsMapFill className="text-warning" />
                  </h1>
                </div>
                <div className="contact-detail">
                  <h5 className="text-white-50">ADDRESS POINT</h5>
                  <p>Sylhet , Bangladesh.</p>
                </div>
              </div>
              <div className="mail d-flex">
                <div className="contact-icon me-4">
                  <h1>
                    <BsFillEnvelopeOpenFill className="text-warning" />
                  </h1>
                </div>
                <div className="contact-detail">
                  <h5 className="text-white-50">MAIL ME</h5>
                  <p>joynal@bintofajjal.info</p>
                </div>
              </div>
              <div className="phone d-flex">
                <div className="contact-icon me-4">
                  <h1>
                    <BsFillTelephoneFill className="text-warning" />
                  </h1>
                </div>
                <div className="contact-detail">
                  <h5 className="text-white-50">CALL ME</h5>
                  <p>+8801636024193</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-12 " data-aos="fade-left">
            <form onSubmit={sendEmail}>
              <div className="form">
                <input type="text" placeholder="Your Name" name="name" /> <br />{" "}
                <br />
                <input
                  type="email"
                  placeholder="Your Email"
                  name="email"
                />{" "}
                <br /> <br />
                <input type="text" placeholder="Your Subject" name="subject" />
                <br /> <br />
                <textarea
                  name=""
                  id=""
                  cols="30"
                  name="message"
                  rows="6"
                  placeholder="Your Message"
                ></textarea>
                <br /> <br />
                <input
                  type="submit"
                  value="SEND MESSAGE"
                  className="btn-warning mb-5"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* ====================== NAVIGATION START=============================*/}
      <div className="navbar desk-nav">
        <div className="nav-links">
          <div className="nav-url">
            <Link to="./home">
              <p className="text-white nav-icon">
                <BsFillHouseFill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./about">
              <p className="text-white nav-icon">
                <BsPersonFill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./portfolio">
              <p className="text-white nav-icon">
                <BsGrid1X2Fill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./contact">
              <p className="text-white nav-icon">
                <BsChatDotsFill />
              </p>
            </Link>
          </div>
          <div className="nav-url">
            <Link to="./blog">
              <p className="text-white nav-icon">
                <BsTextIndentLeft />
              </p>
            </Link>
          </div>
        </div>
      </div>
      {/* FOR MOBILE */}
      <div className="phone-nav" data-aos="fade-down">
        <div className="nav-links">
          <Link to="./home">
            <BsFillHouseFill
              id="target1"
              className="text-white nav-icon fss-9"
            />
          </Link>

          <Link to="./about">
            <BsPersonFill id="target2" className="text-white nav-icon fss-9" />
          </Link>

          <Link to="./portfolio">
            <BsGrid1X2Fill id="target3" className="text-white nav-icon fss-9" />
          </Link>

          <Link to="./contact">
            <BsChatDotsFill
              id="target4"
              className="text-white nav-icon fss-9"
            />
          </Link>
          <Link to="./blog">
            <BsTextIndentLeft
              id="target4"
              className="text-white nav-icon fss-9"
            />
          </Link>
        </div>
      </div>
      {/* ====================== NAVIGATION END=============================*/}
    </div>
  );
};

export default Contact;
